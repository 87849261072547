<template>
	<div class="menu">
		<ul class="nav">
			<template  v-for="item in $parent.nav">
              <li class="nav__item" :key="item.title">
                <router-link class="title small" :to="item.path" v-if="item.path != 'social'">
                  {{ $t(item.title) }}
                </router-link>
                <div class="title small" v-if="item.path === 'social'" @click="$parent.scrollToSocial">
                  {{ $t(item.title) }}
                </div>
              </li>
            </template>
		</ul>
	</div>
</template>

<script>

export default {
	name: 'Menu',
	props: [],
	data: function() {
		return {
			
		}
	},
	methods: {
		
	},
}
</script>
