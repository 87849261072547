<template>
  <footer class="footer">
    <div class="footer-section">
      <div class="wrapper">
        <div class="left">
          <router-link to="/" class="logo">
            <img src="./../images/logo.svg" class="img"/>
          </router-link>
          <div class="desc" v-if="settings">© {{ new Date().getFullYear() }} {{ settings.copyright }}</div>
        </div>
        <div class="right">
          <div class="desc" v-if="settings">{{ settings.requisites }}</div>
        </div>
      </div>
    </div>
    <div class="pay-logos-section">
        <img src="./../images/cards.svg" class="img"/>
        <img src="./../images/multibanco.svg" class="img multibanco"/>
        <img src="./../images/mbway.svg" class="img mbway"/>
    </div>
    <div class="copyright-section">
      <div class="wrapper">
        <div class="center">
          <ul class="nav">
            <template  v-for="item in textPageList">
              <li class="nav__item" :key="item.id">
                <a class="desc" @click="goToPage(item)">
                  {{ $t(item.title) }}
                </a>
              </li>
            </template>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>


<script>

export default {
	name: 'Footer',
	props: ['settings'],
	data: function() {
		return {
      textPageList: []
		}
	},
	methods: {
		getTextPages() {
      this.$http.get(process.env.VUE_APP_API + 'static-pages')
      .then((res) => {
        this.textPageList = res.data.payload
      })
      .catch(() => {
        
      })
    },
    goToPage(item) {
      this.$router.push({path: '/pages/' + item.id, params: {data: item.id}})
      this.$emit('getTextPageContent', item.id);
    }
	},
	mounted() {
    this.getTextPages()
    
  }
}
</script>
