<template>
	<header class="header">
        <div class="wrapper">
          <div class="left">
            <router-link to="/" class="logo">
              <img src="./../images/logo.svg" class="img"/>
            </router-link>
          </div>
          <ul class="nav">
            <template  v-for="item in $parent.nav">
              <li class="nav__item" :key="item.title">
                <router-link class="title small" :to="item.path" v-if="item.path != 'social'">
                  {{ $t(item.title) }}
                </router-link>
                <div class="title small" v-if="item.path === 'social'" @click="$parent.scrollToSocial">
                  {{ $t(item.title) }}
                </div>
              </li>
            </template>
          </ul>
          <div class="right">
            <router-link to="/cart" class="cart-icon button" v-if="$parent.isAuth">
              <img src="./../images/cart.svg" class="img"/>
              <div v-if="$parent.cartContents.length" class="indicator">
                {{$parent.cartContents.length}}
              </div>
            </router-link>
            <div class="button link-container dropdown-open" v-if="$parent.isAuth">
              <span>My profile</span>
              <div class="arrow">
                <svg xmlns="http://www.w3.org/2000/svg" width="16.808" height="10" viewBox="0 0 16.808 10">
                  <path id="Контур_195" data-name="Контур 195" d="M9.233,10.829a1.6,1.6,0,0,1-1.139-.469l-6.8-6.8A1.6,1.6,0,0,1,3.563,1.3l5.67,5.67L14.9,1.3a1.6,1.6,0,0,1,2.266,2.266l-6.8,6.8a1.6,1.6,0,0,1-1.133.469Z" transform="translate(-0.829 -0.829)" fill="#fff"/>
                </svg>
              </div>
              <div class="dropdown">
                <div class="dropdown-wrapper">
                  <div class="nav__item">
                    <router-link to="/profile">
                      Overview
                    </router-link>
                  </div>
                  <div class="nav__item">
                    <router-link to="/balance">
                      MY BALANCE
                    </router-link>
                  </div>
                  <!-- <div class="nav__item">
                    <router-link to="sell-offers">
                      Sell Offers
                    </router-link>
                  </div> -->
                  <div class="nav__item nav__item-logout">
                    <a @click="$parent.logout">
                      <span>LogOut</span>
                      <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18">
                        <path id="log-out_1_" data-name="log-out (1)" d="M5,18a1,1,0,0,0,1,1h8a1,1,0,0,1,0,2H6a3,3,0,0,1-3-3V6A3,3,0,0,1,6,3h8a1,1,0,0,1,0,2H6A1,1,0,0,0,5,6Zm4-6a1,1,0,0,1,1-1h8.586L17.293,9.707a1,1,0,0,1,1.414-1.414l3,3a1,1,0,0,1,0,1.414l-3,3a1,1,0,0,1-1.414-1.414L18.586,13H10A1,1,0,0,1,9,12Z" transform="translate(-3 -3)" fill="#fff" fill-rule="evenodd"/>
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="link-container" v-else>
              <div class="link hover-link" @click="openSignIn()">
                <span class="title small">Sign In</span>
              </div>
              <div class="button" @click="openSignUp()">
                <span>Sign Up</span>
              </div>
            </div>
          </div>
        </div>
    </header>
</template>

<script>

export default {
	name: 'Header',
  props: [],
	data: function() {
		return {
		}
	},
	methods: {
    openSignIn() {
      this.$router.push({path: '/login'});
    },
    openSignUp() {
      this.$router.push({path: '/registration'});
    }
	}
}
</script>
