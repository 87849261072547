<template>
  <div class="modal reg-modal page-inside">
    <div class="main-wrapper">
      <section class="section hero-section">
        <div class="wrapper">
          <div class="container">
            <div class="form">
              <div class="form-wrapper">
                <div class='form-fields register-content'>
                  <div class="title">{{$t('TOP UP')}}</div>
                  <div class="form-fields-wrapper">
                    <label>
                      <div class="desc" :placeholder="$t('First name')">{{$t('First name')}}:</div>
                      <input type="text" :placeholder="$t('First name')" v-model="name"/>
                    </label>
                    <label>
                      <div class="desc" :placeholder="$t('Last Name')">{{$t('Last Name')}}:</div>
                      <input type="text" :placeholder="$t('Last Name')" v-model="surname"/>
                    </label>
                    <label>
                      <div class="desc" :placeholder="$t('Email')">{{$t('Email')}}:</div>
                      <input type="email" :placeholder="$t('Email')" v-model="email"/>
                    </label>
                    <label>
                      <div class="desc" :placeholder="$t('Phone')">{{$t('Phone')}}:</div>
                      <input type="number" :placeholder="$t('Phone')" v-model="phone"/>
                    </label>
                    <label>
                      <div class="desc" :placeholder="$t('Address')">{{$t('Address')}}:</div>
                      <input type="text" :placeholder="$t('Address')" v-model="address"/>
                    </label>
                    <label>
                      <div class="desc" :placeholder="$t('City')">{{$t('City')}}:</div>
                      <input type="text" :placeholder="$t('City')" v-model="city"/>
                    </label>
                    <label>
                      <div class="desc" :placeholder="$t('Country')">{{$t('Country')}}:</div>
                      <select v-model="country">
                        <option v-for="item in countryOptions" :key="item.id">{{$t(item.title)}}</option>
                      </select>
                    </label>
                    <label>
                      <div class="desc" :placeholder="$t('Zip Code')">{{$t('Zip Code')}}:</div>
                      <input type="text" :placeholder="$t('Zip Code')" v-model="zipCode"/>
                    </label>
                    <div class="title topup-amount">
                        {{ topUpAmount }} <img src="./../images/coin.svg" class="coin"/>
                    </div>
                    <div class="cta-container">
                      <div class="chekbox-container">
                        <label class="chekbox-label">
                          <div class="chekbox-label-wrapper">
                            <input type="checkbox" name="terms" v-model="terms"/>
                            <div class="checkbox"></div>
                            <span class="desc">{{$t('I agree with')}} </span>
                            <a @click="$parent.goToPrivacy()" class="desc"> {{$t('privacy policy')}}</a>
                            <span class="desc"> {{$t('and')}} </span>
                            <a @click="$parent.goToTerms()" class="desc"> {{$t('terms and conditions')}}</a>
                          </div>
                        </label>
                      </div>
                      <div class="button-container">
                        <button :class="['button action', {'disabled': !requiredFieldsAreFilled}]" @click="submit('card')">
                          <span>{{$t('Debit / Credit card')}}</span>
                        </button>
                        <img :class="['img action multibanco disabled']" src="../images/multibanco.svg"/>
                        <button :class="['button action mbway', {'disabled': !requiredFieldsAreFilled}]"
                                @click="submit('mbway')">
                          <img src="../images/mbway.svg"/>
                        </button>
                      </div>
                    </div>
                    <transition name="slide">
                      <div v-if="$parent.error" class="error-desc desc red">{{$parent.error}}</div>
                    </transition>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="title small server-status absolute">{{$t('SERVER STATUS')}}: <span class="green">{{$t('ONLINE')}}</span></div>
      </section>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Registration',
  props: [],
  components: {
  },
  data: function() {
    return {
      email: '',
      name: '',
      phone: '',
      surname: '',
      nickname: '',
      country: '',
      countryId: '',
      countryOptions: '',
      city: '',
      address: '',
      zipCode: '',
      terms: false,
      topUpAmount: '',
    }
  },
  mounted() {
    this.getProfile();
    this.getCounties();
    this.setTopUpAmount();
  },
  computed: {
    requiredFieldsAreFilled() {
      let fieldsAreFilled;
      if(
        this.name && this.surname  && this.phone && 
        this.countryId && this.city && this.address  
        && this.zipCode && this.terms && this.email
      ) {
        fieldsAreFilled = true;
      } else {
        fieldsAreFilled = false;
      }
      return fieldsAreFilled;
    }
  },
  watch: {
    country: function() {
      for (let i = 0; i < this.countryOptions.length; i++) {
        if (this.country === this.countryOptions[i].title) {
          this.countryId = this.countryOptions[i].id
          break;
        }
      }
      this.countryId
    },
  },
  methods: {
    submit(paymentType) {
      let data = {
        "name": this.name,
        "surname": this.surname,
        "phone": this.phone,
        "email": this.email,
        "country": this.countryId,
        "city": this.city,
        "address": this.address,
        "postCode": this.zipCode,
        "amount": this.topUpAmount,
        paymentType
      }
      this.$emit('topupSubmit', data)
    },
    getCounties() {
      this.$http.get(process.env.VUE_APP_API + 'countries')
      .then((res) => {
          this.countryOptions = res.data.payload;
      })
      .catch(() => {
        
      })
    },
    getProfile() {
      this.$http.get(process.env.VUE_APP_API + 'user/profile')
      .then((res) => {
          this.name = res.data.name;
          this.surname = res.data.surname;
          this.phone = res.data.phone;
          this.email = res.data.email;
          this.nickname = res.data.nickname;
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignInModal();
        }
      })
    },
    setTopUpAmount() {
        this.topUpAmount = this.$parent.topUpAmount;
    }
  }
}
</script>