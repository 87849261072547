<template>
  <main class="main page-inside products-page categories-page">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="title big">{{$t('PRODUCTS')}}</div>
        <div class="wrapper">
          <transition name="fade">
            <div class="list products" v-if="$parent.categories">
              <div class="item" v-for="(item, i) in $parent.categories" :key="i">
                <div class="item-wrapper">
                  <div class="preview">
                    <img :src="imgDomain  + item.image_url" class="img"/>
                  </div>
                  <button class="button" @click="$emit('chooseCategoty', item)">{{$t(item.title)}}</button>
                </div>
              </div>
            </div>
          </transition>
        </div>
        <div class="title small server-status absolute">{{$t('SERVER STATUS')}}: <span class="green">{{$t('ONLINE')}}</span></div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: 'Categories',
  props: [],
  components: {
  },
  data: function() {
    return {
      imgDomain: ''
    }
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
  },
  methods: {
    
  }
}
</script>