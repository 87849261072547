<template>
  <main class="main cart-page page-inside" v-if="$parent.isAuth">
    <div class="main-wrapper">
      <section class="section hero-section">
        <div class="wrapper">
          <div class="title">{{$t('CART')}}</div>
          <div class="desc empty-desc" v-if="!$parent.cartContents.length">
            {{$t('Cart is empty!')}}
          </div>
          <div class="left" v-if="$parent.cartContents.length">
            <div class="product-list">
              <div class="item" v-for="(item, i) in $parent.cartContents" :key="i">
                <div class="preview">
                  <img :src="imgDomain + item.item.img_url" class="img"/>
                </div>
                <div class="title name">{{item.item.title}}</div>
                <div class="count">
                  <div class="count__item minus" @click="$parent.removeOneFromCart(item, item.item_id)"></div>
                  <input type="number" value="1" v-model="item.count" step="1"/>
                  <div class="count__item plus" @click="$parent.addToCart(item.item, item.item_id)"></div>
                </div>
                <div class="price">{{item.item.price}} <span class="currency">{{$parent.currency}}</span></div>
                <div class="delete" @click="$parent.removeFromCart(item.item, item.item_id)">
                  <img src="./../images/close-blue.svg" class="img"/>
                  <div class="title">{{$t('DELETE')}}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="right" v-if="$parent.cartContents.length">
            <div class="wrapper">
              <div class="row">
                <div class="desc">{{$t('Total to pay')}}:</div>
                <div class="title">{{$parent.totalSum}} <span class="currency">{{$parent.currency}}</span></div>
              </div>
              <button @click="toOrder" class="button">
                <span>{{$t('Order')}}</span>
              </button>
              <div class="delete" @click="$parent.emptyCart">
                <img src="./../images/close-blue.svg" class="img"/>
                <div class="title">{{$t('CLEAR CART')}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="title small server-status absolute">{{$t('SERVER STATUS')}}: <span class="green">{{$t('ONLINE')}}</span></div>
      </section>
    </div>
  </main>
</template>
<script>

export default {
  name: 'Cart',
  props: [],
  components: {
  },
  data: function() {
    return {
      imgDomain: ''
    }
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
  },
  methods: {
    toOrder() {
      this.$router.push({ path: '/order' })
    }
  }
}
</script>