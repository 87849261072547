
import Vue from 'vue'
import App from './App.vue'
import router from './router.js'
import i18n from './i18n'
import axios from 'axios'
Vue.prototype.$http = axios


global.jQuery = require('jquery');
var $ = global.jQuery;
window.$ = $;

import './../node_modules/slick-carousel/slick/slick.css';
import '../public/css/style.scss';
import 'promise-polyfill/src/polyfill';

Vue.prototype.$http.defaults.withCredentials = true;

new Vue({
	router:router,
	render: h => h(App),
	i18n
}).$mount('#app')
