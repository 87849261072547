<template>
  <main class="main home-page">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="wrapper home">
          <div class="left">
            <div class="title big">{{$t('WELCOME')}}</div>
            <div class="title small message">{{$t("We're excited to welcome you to our community of players!")}}</div>
            <div class="desc">
              {{$t("Welcome to Craft-ify.com, an unofficial Minecraft survival server with mods to suit every taste. We invite you to join our community of players and immerse yourself in a thrilling world of cubes where you can build your own village or city and create clans to build new worlds.")}}
            </div>
            <div class="title small server-status">{{$t('SERVER STATUS')}}: <span class="green">{{$t('ONLINE')}}</span></div>
          </div>
          <div class="right">
            <img src="./../images/hero.png" class="img"/>
          </div>
        </div>
      </div>
      <div class="section section-2">
        <img src="./../images/line-1.png" class="line"/>
        <div class="wrapper">
          <div class="left">
            <img src="./../images/guys.png" class="img"/>
          </div>
          <div class="right">
            <input class="input" type="text" v-model="nickname" :placeholder="$t('Enter your nickname')"/>
            <button class="button" @click="$emit('postNickname', nickname)">{{$t('SELECT PRODUCTS')}}</button>
            <transition name="slide">
              <div v-if="$parent.error" class="error-desc desc red">{{$parent.error}}</div>
            </transition>
          </div>
        </div>
      </div>
      <div class="bg-2-container">
        <div class="section section-3">
          <div class="wrapper">
            <div class="title">{{$t('FAQ')}}</div>
            <div class="accordion">
              <div class="item" v-for="(item, index) in $parent.faq" :key="index">
                <div class="panel" @click="togglePanel(index)">
                  <Transition>
                    <img src="./../images/close.svg" class="close" v-if="isActivePanel(index)"/>
                  </Transition>
                  <Transition>
                    <img src="./../images/plus.svg" class="plus" v-if="!isActivePanel(index)"/>
                  </Transition>
                  <div class="title small">{{ item.question }}</div>
                </div>
                <div class="desc" v-if="isActivePanel(index)">
                  {{ item.answer }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="section section-4">
          <img src="./../images/line-2.png" class="line2"/>
          <div class="wrapper">
            <div class="left">
              <div class="img-container">
                <img src="./../images/guy.png" class="img"/>
                <img src="./../images/heart1.png" class="heart1"/>
                <img src="./../images/heart2.png" class="heart2"/>
                <img src="./../images/heart3.png" class="heart3"/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-3-container">
        <div class="section section-5">
          <div class="wrapper">
            <div class="title small">{{$t('ARE YOU ALREADY A PART OF OUR COMMUNITY?')}}</div>
            <div class="desc">{{$t('Please assist us in spreading the word about our server by leaving a review for other players.')}}</div>
            <label>
              <div class="desc">{{$t('Email')}}:</div>
              <input class="input" type="email" v-model="email" />
            </label>
            <label>
              <div class="desc">{{$t('Nickname')}}:</div>
              <input class="input" type="text" v-model="name" />
            </label>
            <label>
              <div class="desc">{{$t('Feedback')}}:</div>
              <textarea class="input" v-model="message"></textarea>
            </label>
            <button class="button" @click="submit">{{$t('SEND')}}</button>
            <transition name="fade">
              <div v-if="$parent.successMessage" class="desc small green">{{$parent.successMessage}}</div>
            </transition>
            <transition name="slide">
              <div v-if="$parent.error" class="error-desc desc red">{{$parent.error}}</div>
            </transition>
          </div>
        </div>
        <div class="section section-6" id="social">
          <div class="wrapper ">
            <div class="title">{{$t('SOCIAL MEDIA')}}</div>
            <div class="list">
              <a v-for="link in $parent.socialLinks" :href="link.link" class="item" :key="link.img">
                <div class="image">
                  <img :src="link.img" class="img"/>
                </div>
                <div class="text">
                  <div class="title small">{{ link.title }}</div>
                  <div class="title small blue">{{$t('SUBSCRIBE')}}</div>
                </div>
              </a>
            </div>
          </div>
          <div class="title small server-status absolute">{{$t('SERVER STATUS')}}: <span class="green">{{$t('ONLINE')}}</span></div>
        </div>
        <Footer :settings="$parent.settings"/>
      </div>
    </div>
  </main>
</template>
<script>


import Footer from '../components/Footer.vue'
export default {
  name: 'Home',
  props: [],
  components: {
    Footer
  },
  data: function() {
    return {
      email: '',
      name: '',
      message: '',
      nickname: '',
      activePanels: []
    }
  },
  mounted() {
  },
  methods: {
    togglePanel(index) {
      if (this.isActivePanel(index)) {
        this.activePanels.splice(this.activePanels.indexOf(index), 1)
      } else {
        this.activePanels.push(index)
      }
    },
    isActivePanel(index) {
      return this.activePanels.includes(index)
    },
    submit() {
      let data = {
        "name": this.name,
        "email": this.email,
        "message": this.message
      }
      this.$emit('sendContactForm', data);
    }
  }
}
</script>