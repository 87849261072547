<template>
  <main className="main page-inside page-not-found payment-result-page">
    <div className="main-wrapper">
      <div className="section hero-section">
        <div className="wrapper">
          <div className="title big">{{ $t('404') }}</div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>

export default {
  name: 'PageNotFound',
  props: [],
  components: {},
  data: function () {
    return {}
  },
  methods: {}
}
</script>
