<template>
  <main class="main page-inside balance-page">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="wrapper">
          <div class="title">{{$t('MY BALANCE')}}</div>
          <div class="flex">
            <div class="card">
              <div class="balance-amount">
                <div class="title">{{ balance }}</div>
                <img src="./../images/coin.svg" class="coin"/>
              </div>
              <div class="title small">
                <b>{{$t('TOP UP BALANCE')}}</b>
              </div>
              <label>
                <div class="desc">{{$t('Enter amount:')}}</div>
                <input type="text" @input="allowOnlyNumbers" placeholder="Enter amount" v-model="amount" />
                <img src="./../images/coin.svg" class="coin"/>
              </label>
              <div class="desc or">{{$t('or choose:')}}</div>
              <div class="radio-list">
                <label class="chekbox-label" v-for="(item, i) in amountList" :key="i">
                  <div class="chekbox-label-wrapper">
                    <input type="radio" v-model="amount" :value="item" />
                    <div class="checkbox"></div>
                    <span class="title small">
                      {{ item }}
                    </span>
                    <img src="./../images/coin.svg" class="coin"/>
                  </div>
                </label>
              </div>
              <button :class="['button action', {'disabled': !requiredFieldsAreFilled}]" @click="toCheckout">
                <span>{{$t('TOP UP')}}</span>
              </button>
            </div>
            <div class="card">
              <div class="profile-table">
                <div class="profile-table-wrapper profile-form-wrapper">
                  <div class="title small">{{$t('PAYMENT HISTORY')}}</div>
                  <div class="table-container" v-if="$parent.depositHistory.length">
                    <table>
                      <thead>
                        <tr>
                          <td>ID</td>
                          <td>Date</td>
                          <td>Amount</td>
                          <td>Status</td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item,i) in $parent.depositHistory" :key="i">
                          <td>{{item.id}}</td>
                          <td>{{$parent.formatDate(item.created_at)}}</td>
                          <td>
                            <span>{{item.sum}} </span>
                            <img src="./../images/coin.svg" class="coin"/>
                          </td>
                          <td :class="[{'green': item.status == 'Success'}, {'white': item.status == 'Pending'}]">{{item.status}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="title small title-empty" v-if="!$parent.depositHistory.length">{{$t('Empty!')}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>

export default {
  name: 'BalancePage',
  props: [],
  components: {
  },
  data: function() {
    return {
      amount: '25',
      amountList: [25,50,100,250,500],
      balance: 0,
    }
  },
  mounted() {
    this.$http.get(process.env.VUE_APP_API + 'user/profile')
    .then((res) => {
        this.balance = res.data.balance;
    })
    .catch((res) => {
      if(res.response.status === 401) {
        this.$parent.openSignInModal();
      }
    })
  },
  computed: {
    requiredFieldsAreFilled() {
      let fieldsAreFilled;
      if(
        this.amount 
      ) {
        fieldsAreFilled = true;
      } else {
        fieldsAreFilled = false;
      }
      return fieldsAreFilled;
    }
  },
  methods: {
    allowOnlyNumbers(){
      this.amount = this.amount.replace(/[^0-9.,]/g, '');
      const parts = this.amount.split(/[.,]/);
      const integerPart = parts[0];
      let decimalPart = parts[1];
      if (decimalPart !== undefined && decimalPart.length > 2) {
        decimalPart = decimalPart.slice(0, 2);
      }
      this.amount = decimalPart !== undefined ? `${integerPart}.${decimalPart}` : integerPart;
    },
    toCheckout() {
      this.$emit('setTopUpAmount', this.amount);
    },
  }
}
</script>